/* BLUE KEYFRAMES */

@keyframes slideBlueInLeft {
    0% {
        transform: translateX(100%);
        background-color: #3b82f6;
        z-index: -5;
    }

    100% {
        transform: translateX(0%);
        background-color: #3b82f6;
        z-index: -5;
    }
}

@keyframes slideBlueInRight {
    0% {
        transform: translateX(-100%);
        background-color: #3b82f6;
        z-index: -5;
    }

    100% {
        transform: translateX(0%);
        background-color: #3b82f6;
        z-index: -5;
    }
}

@keyframes slideBlueOutRight {
    0% {
        transform: translateX(0%);
        background-color: #3b82f6;
        z-index: -5;
    }

    100% {
        transform: translateX(100%);
        background-color: #3b82f6;
        z-index: -5;
    }
}

@keyframes slideBlueOutLeft {
    0% {
        transform: translateX(0%);
        background-color: #3b82f6;
        z-index: -5;
    }

    100% {
        transform: translateX(-100%);
        background-color: #3b82f6;
        z-index: -5;
    }
}

/* GRAY KEYFRAMES */

@keyframes slideGrayInLeft {
    0% {
        transform: translateX(-100%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }

    100% {
        transform: translateX(0%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }
}

@keyframes slideGrayToRight {
    0% {
        transform: translateX(-100%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }

    100% {
        transform: translateX(0%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }
}

@keyframes slideGrayOutRight {
    0% {
        transform: translateX(0%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }

    100% {
        transform: translateX(100%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }
}

@keyframes slideGrayOutLeft {
    0% {
        transform: translateX(100%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }

    100% {
        transform: translateX(0%);
        background-color: rgb(229 231 235);
        z-index: -5;
    }
}

.button {
    position: relative;
    overflow: hidden;
}

.button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.button.bg-blue-500::before {
    background-color: rgb(59, 130, 246);
    /* Azul */
}

.button.bg-gray-200::before {
    background-color: rgb(229 231 235);
    /* Cinza */
}

/* BLUE ANIMATION */

.button.anim-blue-in-left::before {
    animation: slideBlueInLeft 0.2s ease forwards;
}

.button.anim-blue-in-right::before {
    animation: slideBlueInRight 0.2s ease forwards;
}

.button.anim-blue-out-right::before {
    animation: slideBlueOutRight 0.4s ease forwards;
}

.button.anim-blue-out-left::before {
    animation: slideBlueOutLeft 0.4s ease forwards;
}

/* GRAY ANIMATION */

.button.anim-gray-in-left::before {
    animation: slideGrayInLeft 0.2s ease forwards;
}

.button.anim-gray-in-right::before {
    animation: slideGrayToRight 0.4s ease forwards;
}

.button.anim-gray-out-right::before {
    animation: slideGrayOutRight 0.4s ease forwards;
}

.button.anim-gray-out-left::before {
    animation: slideGrayOutLeft 0.4s ease forwards;
}

@keyframes textColorToWhite {
    from {
        color: #3b82f6;
    }

    to {
        color: #ffffff;
    }
}

@keyframes textColorToGray {
    from {
        color: #ffffff;
    }

    to {
        color: #1f293793;
    }
}

.button.anim-text-color-to-white {
    animation: textColorToWhite 0.3s forwards;
}

.button.anim-text-color-to-gray {
    animation: textColorToGray 0.3s forwards;
}
